import React from "react"
import { graphql } from "gatsby"
import Container from "react-bootstrap/Container"
import { Card, Col, Row } from "react-bootstrap"
import Link from "../UI/Link"
import SectionHeading from "../UI/SectionHeading"
export const fragment = graphql`
  fragment twoTextBoxesWithFillFragment on WpPage_Flexlayouts_FlexibleLayouts_TwoTextBoxesWithFill {
    sectionHeader
    headingType
    boxes {
      link {
        target
        title
        url
      }
      text
    }
  }
`

const TwoTextBoxesWithFill = ({ sectionHeader, headingType, boxes }) => {
  return (
    <section className={`bg-pale-grey`}>
      <Container>
        <div className={`m-auto px-xl-7`}>
          <Row>
            <Col>
            <SectionHeading
            className="pb-2 pb-xl-5 pt-3 pt-lg-2 text-center"
            text={sectionHeader} type={headingType} />
            </Col>
          </Row>
          <Row className="mx-auto">
            {boxes.map((item, i) => {
              return (
                <Col
                  key={`boxes${i}`}
                  xs={12}
                  md={6}
                  className={`pt-3 pt-xl-0 pb-3`}>
                  <Card className={`border-0 p-1 box`}>
                    <Card.Body className="d-flex align-items-center flex-column">
                      <div className="mt-auto d-flex align-items-center flex-column">
                        <div className="text-center my-3">
                          <Link
                            classes="linkClass h3"
                            link={item.link.url}
                            title={item.link.title} />
                        </div>
                        <p
                          dangerouslySetInnerHTML={{ __html: item.text }}
                          className="text-center py-2"
                        />
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </div>
      </Container>
    </section>
  )
}

export default TwoTextBoxesWithFill
